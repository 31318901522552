<template>
  <section>
    <AuctionsCategories
      class="bg-e0"
      ref="categories"
      :type="categoriesType"
    />
    <slot></slot>
  </section>
</template>

<script>
import AuctionsCategories from "@/components/Auctions/AuctionsCategories"

export default {
  name: "ListContainer",
  components: {AuctionsCategories},
  watch: {},
  computed: {
    adminList() {
      return this.$route.name === 'auctions.list';
    },
    categoriesType() {
      let type = 'users'
      if (this.adminList)
        type = 'auctions'
      return type;
    },
  }
}
</script>